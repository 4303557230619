export default {
  palette: {
    primary: '#b8460f',
    primaryTransparent: 'rgba(241,134,38, 0.12)',
    secondary: '#005d95',
    secondaryTransparent: 'rgba(0,93,149,0.12)',
    background: '#eff8fb',
    buttonText: '#0C282A',
    text: '#494948',
    white: '#ffffff',
  },
  mediaQueries: {
    large: '70rem',
    medium: '60rem',
    small: '40rem',
  },
  spacing: {
    xs: '0.5rem',
    small: '1rem',
    medium: '1.5rem',
    large: '2rem',
    xlarge: '4rem',
  },
};
